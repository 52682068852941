import { UserInfoAdapter } from '~/repository/modules/mainPage/MainPage.Adapters';
import type { IUserInfoEntity } from '~/repository/modules/mainPage/MainPageEntities.types';
import { useUserStore } from '~/store/user/user.store';
import { useAlertStore } from '~/store/alert/alert.store';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { ICasesItem } from '~/features/mainPage/Types/cases/client.types';
import { normalizeObject } from '~/features/mainPage/utils/normalizeDailyCaseObject';
import type { TPossibleNull } from '~/types/Shared.types';

/* Хранилище контроллера ежедневных бесплатных кейсов */
export const useDailyCasesStore = defineStore('mainPage/dailyCases', () => {
  const {
    $api: { mainPage: MainPageService, dailyCases: DailyCasesApi },
  } = useNuxtApp();

  const isLoaded = ref(false);
  const alertStore = useAlertStore();
  const userStore = useUserStore();

  const dailyCases = ref<ICasesItem[]>([]);
  const LINE_BREAKS = 5;

  const colorsOptions = ref({
    background: 'linear-gradient(180deg, #C88D1C 0%, #760178 100%)',
    circle: 'radial-gradient(50% 50% at 50% 50%, #FFCC46 87.5%, #FFE39A 100%)',
    hover: 'linear-gradient(180deg, #FFCB45 0%, #A910AC 100%)',
    shadow: '#FFDC81',
  });
  const eventData = ref({
    color: 'linear-gradient(270deg, rgba(17, 7, 34, 0.00) 0%, #FFCA44 100.67%)',
    icon: '',
  });

  const titleOptions = ref({
    image: '/static/case/daily/title-icon.png',
    lineColor: 'linear-gradient(270deg, #FFCA44 0%, rgba(17, 7, 34, 0) 100.67%)',
    text: 'Free daily cases',
    textColor: 'linear-gradient(180deg, #FFF 17.86%, #FF77F9 78.57%)',
  });

  const { user } = storeToRefs(userStore);

  /* Информация о юзере */
  const userInfo = computed<Partial<IUserInfoEntity>>(() => (user.value ? UserInfoAdapter(user.value) : {}));
  // Время до следующего вайпа
  const secondsUntilEnd = ref(0);
  const isTimerLoaded = ref(false);

  const getTimerInfo = async () => {
    try {
      isTimerLoaded.value = false;
      const response = await DailyCasesApi.getTimerInfo();
      if (response && response?.secondsUntilEnd !== null) {
        secondsUntilEnd.value = response.secondsUntilEnd;
      } else {
        secondsUntilEnd.value = 0;
      }
    } catch {
      secondsUntilEnd.value = 0;
    } finally {
      isTimerLoaded.value = true;
    }
  };

  const generateLevel = async () => {
    try {
      const result = await MainPageService.generateUserLevel();
      if (!result) return false;

      await userStore.getMe();
      return true;
    } catch {
      return false;
    }
  };

  const wasGeneratedLevel = computed<boolean>(() => !!userInfo.value.wasGeneratedLvl);

  const nextLevelCase = computed<TPossibleNull<ICasesItem>>(() => {
    return dailyCases.value.find((item) => item.locked) ?? null;
  });

  // Достаем список бесплатных кейсов
  const fetchDailyCases = async () => {
    try {
      isLoaded.value = false;
      const response = await DailyCasesApi.getCases();
      if (!response)
        return alertStore.showError({
          message: 'Error while fetching daily cases',
          title: ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      dailyCases.value = normalizeObject(response);
    } catch (e) {
      alertStore.showError({
        message: (e as { msg?: string }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      isLoaded.value = true;
    }
  };

  const getDailyImg = (level: number, extension = 'avif') => `/static/main-page/dailyCases/lvl${level}.${extension}`;
  const levels = [1, 5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 160, 180, 200];
  const pngLevels = [1, 140, 160, 180, 200];

  const dailyCaseImages = levels.map((level) => {
    const extension = pngLevels.includes(level) ? 'png' : 'avif';

    return {
      id: level,
      src: getDailyImg(level, extension),
    };
  });

  const userLevel = ref<string>('');
  watch(
    user,
    (newVal, oldValue) => {
      if (newVal?.userLevel && oldValue?.userLevel && oldValue.userLevel !== newVal.userLevel) {
        userLevel.value = newVal.userLevel;
      }
    },
    { deep: true },
  );

  const getImagePath = computed(() => (imageId: string) => {
    return dailyCaseImages.find((image) => image.id === +imageId)?.src;
  });

  return {
    LINE_BREAKS,
    colorsOptions,
    dailyCases,
    eventData,
    secondsUntilEnd,
    getTimerInfo,
    fetchDailyCases,
    generateLevel,
    isLoaded,
    isTimerLoaded,
    titleOptions,
    userInfo,
    wasGeneratedLevel,
    dailyCaseImages,
    nextLevelCase,
    userLevel,
    getImagePath,
  };
});
